import React, { Component, useRef } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Container, Row, Col, Input, Button, Table, Spinner, Toast, ToastBody, ToastHeader } from 'reactstrap';
import './layout/site.css';
import userFunctions from './UserFunctions';
import Upload from "./upload-files-student.component";
import Autocomplete from "./Autocomplete";
import { IoTrashOutline } from 'react-icons/io5';
import Modal from 'react-modal';

export class StudentDetails extends Component {
    static displayName = StudentDetails.name;

    constructor(props) {
        super(props);
        this.raceOtherRef = React.createRef();

        this.state = {
            active: true,
            studentId: userFunctions.GuidEmpty(),
            firstName: '',
            preferredName: '',
            lastName: '',
            dateOfBirth: '',
            zip: '',
            districtId: userFunctions.GuidEmpty(),
            siteId: userFunctions.GuidEmpty(),
            gradeId: userFunctions.GuidEmpty(),
            teacher: '',
            roomNumber: '',
            genderId: userFunctions.GuidEmpty(),
            ethnicityId: userFunctions.GuidEmpty(),
            medicaid: false,
            medicaidNumber: '',
            reducedLunch: false,
            examConsentOnFile: false,
            photoConsent: false,
            IEP504Plan: false,
            wearsGlassesPerNurse: false,
            parentName: '',
            parentMobilePhone: '',
            email: '',

            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false,
            currentPage: 1,
            itemsPerPage: 10,
            numberOfPages: 0,
            districts: [],
            showToast: false,
            toastMessage: '',
            userId: '',
            showMedicaidField: false,
            siteList: [],
            gradeList: [],
            genderList: [],
            ethnicityList: [],
            teacherList: [],
            screenings: [],
            exams: [],
            raceList: [],
            uploadDisabled: true,
            a1: false,
            a2: false,
            a3: false,
            a4: false,
            a5: false,
            a6: false,
            a7: false,
            a8: false,
            a9: false,
            a10: false,
            a11: false,
            a12: false,
            a13: false,
            a14: false,
            a15: false,
            a16: false,
            a17: false,
            a18: false,
            showEmailError: false,
            toastErrorMessage: '',
            isDoctor: false,
            isSupport: false,
            labFrames: [],
            showDeleteStudentModal: false,
            showDistrictOther: false,
            isRaceOther: false,
            raceId: userFunctions.GuidEmpty(),
            raceOther: '',
            labFrameId: userFunctions.GuidEmpty(),
            showModal: false,
            studentRaces: [],
            studentRacesCheckedState: [],
            a1c: '',
            diabetesYear: ''
        };
        this.inputRef = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

        window.addEventListener('resize', this.setWindowDimensions);
        document.getElementsByTagName('html')[0].className = "background-ring";
    }

    async deleteFramePrompt(id) {
        this.setState({ labFrameId: id, showModal: true });
    }

    async deleteFrame() {

        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        await fetch('api/Lab/DeleteFrame?labFrameId=' + this.state.labFrameId + "&adjustInventory=true", {
            method: 'POST',
            body: {},
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                return response.json()
            })
            .then(async (data) => {
                await this.getLabFrames(this.state.studentId);
                this.setState({ loading: false, showModal: false });
            })
            .catch((error) => {
                console.log('error in deleteFrame - ' + error);
                this.setState({ loading: false, showModal: false });
            });
    }


    handleClick(event) {
        let id = Number(event.target.id);

        if (id === 0) {
            this.setState({
                currentPage: 1
            });
        } else if (id === -1) {
            this.setState({
                currentPage: Math.ceil(this.state.sites.length / this.state.itemsPerPage)
            });
        } else {
            this.setState({
                currentPage: Number(event.target.id)
            });
        }
    }

    handleChange(event) {
        this.setState({ currentPage: Number(event.target.value) });
    }

    handlePageSizeChange(event) {
        let pageSize = Number(event.target.value);
        this.setState({ itemsPerPage: pageSize });
        if (this.state.currentPage > Math.ceil(this.state.sites.length / pageSize)) {
            this.setState({ currentPage: Math.ceil(this.state.sites.length / pageSize) })
        }
    }

    async componentDidMount() {
        this.setWindowDimensions();
        //const isAdmin = await AdminFunctions.isAdminAsync();
        const userId = await userFunctions.UserId();
        this.setState({ userId: userId });
        const username = await userFunctions.Username();
        this.setState({ username: username });

        const isInRole = await userFunctions.IsInRole("Doctor");
        const isInRoleSupport = await userFunctions.IsInRole("Operational Support");

        this.setState({ isDoctor: isInRole, isSupport: isInRoleSupport });

        this.getDistricts();
        this.getGrades();
        this.getGenders();
        this.getEthnicities();
        this.getTeachersList();

        this.query = new URLSearchParams(window.location.search);
        this.queryId = this.query.get('Id');
        if (this.queryId) {
            this.setState({ studentId: this.queryId });
            //Get the student and load the page fields
            this.GetStudentById(this.queryId);
            this.GetExamGrid(this.queryId);
            this.getLabFrames(this.queryId);
        }
    }

    async getLabFrames(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Lab/GetLabFrames?studentId=' + id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    labFrames: data,
                    loading: false
                });
            })
            .catch((error) => {
                console.log('error in getLabData - ' + error.message);
                this.setState({ loading: false });
            });
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            //this.searchSites();
        }
    }

    render() {
        // Logic for displaying sites!
        // Logic for displaying sites!
        //const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
        //const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
        //const currentItems = this.state.siteNotes.length > 0 ? this.state.siteNotes.slice(indexOfFirstItem, indexOfLastItem) : [];

        //const renderItems = currentItems.map((note, i) => {
        //    return (
        //        <tr key={i} onClick={(e) => {
        //            this.setState({
        //                siteNoteId: note.id,
        //                siteNotesContactId: note.contactId == null ? 9999 : note.contactId,
        //                showNotesContactName: note.contactId == null ? true : false,
        //                siteNotesContactName: note.name,
        //                siteNotesNote: note.notes
        //            });

        //        }}>
        //            <td>{note.shortNote}</td>
        //            <td>{note.name}</td>
        //            <td>{note.noteDateTime}</td>
        //            <td>{note.user}</td>
        //        </tr >
        //    )
        //});

        //// Logic for displaying page numbers
        //const pageNumbers = [];
        //for (let i = 1; i <= Math.ceil(this.state.sites.length / this.state.itemsPerPage); i++) {
        //    pageNumbers.push(i);
        //}

        //const renderPageNumbers = pageNumbers.map(number => {
        //    return (
        //        <option value={number}>{number}</option>
        //    );
        //});

        return (
            <div>
                {this.state.loading === true ?
                    <div>
                        <Spinner color="primary" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                    </div>
                    : null
                }
                {this.state.showToast ?
                    <Toast className="toast">
                        <ToastBody className="toast-body">
                            {this.state.toastMessage}
                        </ToastBody>
                    </Toast>
                    : null}
                {this.state.showEmailError ?
                    <Toast className="toast-error">
                        <ToastBody className="toast-body">
                            {this.state.toastErrorMessage}
                        </ToastBody>
                    </Toast>
                    : null}
                <Container style={{ marginTop: '25px' }}>
                    <Row>
                        <Col>
                            <h3>Student Information</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='5' lg='6' md='6' sm='10' xs='10'>
                            <table className="studentTable">
                                <tbody>
                                    <tr>
                                        <td>
                                            <label className={'row-label'}>First Name</label>
                                        </td>
                                        <td>
                                            <Input
                                                type='text'
                                                onChange={(e) => {
                                                    this.setState({ firstName: e.target.value, a1: false });
                                                }}
                                                name='First Name'
                                                placeholder={this.state.a1 ? 'Required' : 'First Name'}
                                                value={this.state.firstName ?? ''}
                                                style={this.state.a1 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                autoFocus
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className={'row-label'}>Preferred Name</label>
                                        </td>
                                        <td>
                                            <Input
                                                type='text'
                                                onChange={(e) => {
                                                    this.setState({ preferredName: e.target.value, a2: false });
                                                }}
                                                name='Preferred Name'
                                                placeholder={this.state.a2 ? 'Required' : 'Preferred Name'}
                                                value={this.state.preferredName ?? ''}
                                                style={this.state.a2 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className={'row-label'}>Last Name</label>
                                        </td>
                                        <td>
                                            <Input
                                                type='text'
                                                onChange={(e) => {
                                                    this.setState({ lastName: e.target.value, a3: false });
                                                }}
                                                name='Last Name'
                                                placeholder={this.state.a3 ? 'Required' : 'Last Name'}
                                                value={this.state.lastName ?? ''}
                                                style={this.state.a3 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className={'row-label'}>Date Of Birth</label>
                                        </td>
                                        <td>
                                            <Input
                                                type='date'
                                                onChange={(e) => {
                                                    this.setState({ dateOfBirth: e.target.value, a4: false });
                                                }}
                                                name='Date Of Birth'
                                                placeholder={this.state.a4 ? 'Required' : 'Date Of Birth'}
                                                value={this.state.dateOfBirth ?? ''}
                                                style={this.state.a4 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className={'row-label'}>Home Zip Code</label>
                                        </td>
                                        <td>
                                            <Input
                                                type='text'
                                                onChange={(e) => {
                                                    this.setState({ zip: e.target.value, a5: false });
                                                }}
                                                name='Home Zip Code'
                                                maxLength={5}
                                                placeholder={this.state.a5 ? 'Required' : 'Home Zip Code'}
                                                value={this.state.zip ?? ''}
                                                style={this.state.a5 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className={'row-label'}>District</label>
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                onChange={(e) => {
                                                    this.setState({
                                                        districtId: e.target.value,
                                                        a6: false
                                                    });
                                                    this.getSitesByDistrictId(e.target.value, e.target.options[e.target.selectedIndex].text == 'Other' ? true : false);
                                                }}
                                                value={this.state.districtId}
                                                style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            >
                                                <option key={0} value={0}>---Select---</option>
                                                {this.state.districts.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                    </tr>
                                    {this.state.showDistrictOther ?
                                        <tr>
                                            <td>
                                                <label className={'row-label'}>District (Other)</label>
                                            </td>
                                            <td>
                                                <Input
                                                    type='text'
                                                    onChange={(e) => {
                                                        this.setState({ districtOther: e.target.value });
                                                    }}
                                                    name='DistrictOther'
                                                    maxLength={100}
                                                    placeholder={'District (Other)'}
                                                    value={this.state.districtOther ?? ''}
                                                    style={this.state.showDistrictOther ? { display: 'inline' } : { display: 'none' }}
                                                    autoFocus={this.state.showDistrictOther}
                                                />
                                            </td>
                                        </tr> : null}
                                    <tr>
                                        <td>
                                            <label className={'row-label'}>School</label>
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                onChange={(e) => this.setState({ siteId: e.target.value, a7: false })}
                                                value={this.state.siteId}
                                                disabled={this.state.showDistrictOther}
                                                style={this.state.a7 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            >
                                                <option key={0} value={0}>---Select---</option>
                                                {this.state.siteList?.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                    </tr>
                                    {this.state.showDistrictOther ?
                                        <tr>
                                            <td>
                                                <label className={'row-label'}>School (Other)</label>
                                            </td>
                                            <td>
                                                <Input
                                                    type='text'
                                                    onChange={(e) => {
                                                        this.setState({ schoolOther: e.target.value });
                                                    }}
                                                    name='schoolOther'
                                                    maxLength={100}
                                                    placeholder={'School (Other)'}
                                                    value={this.state.schoolOther ?? ''}
                                                    style={this.state.showDistrictOther ? { display: 'inline' } : { display: 'none' }}
                                                />
                                            </td>
                                        </tr> : null}
                                    <tr>
                                        <td>
                                            <label className={'row-label'}>Grade</label>
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                onChange={(e) => this.setState({ gradeId: e.target.value, a8: false })}
                                                value={this.state.gradeId}
                                                style={this.state.a8 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            >
                                                <option key={0} value={0}>---Select---</option>
                                                {this.state.gradeList.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className={'row-label'}>Teacher</label>
                                        </td>
                                        <td>
                                            <Autocomplete
                                                suggestions={this.state.teacherList}
                                                onChange={(e) => {
                                                    var value = "";
                                                    var obj = e;
                                                    if (obj.Text == undefined) {
                                                        value = obj;
                                                    }
                                                    else {
                                                        value = obj.Text;
                                                    }

                                                    this.setState({ teacher: value, a9: false });
                                                }}
                                                placeholder={this.state.a9 ? 'Required' : 'Teacher'}
                                                value={this.state.teacher ?? ''}
                                                style={this.state.a9 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className={'row-label'}>Room #</label>
                                        </td>
                                        <td>
                                            <Input
                                                type='text'
                                                onChange={(e) => { this.setState({ roomNumber: e.target.value, a10: false }); }}
                                                name='RoomNumber'
                                                placeholder={this.state.a4 ? 'Required' : 'Room #'}
                                                value={this.state.roomNumber ?? ''}
                                                style={this.state.a10 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                className="form-control"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className={'row-label'}>Sex</label>
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                onChange={(e) => this.setState({ genderId: e.target.value, a11: false })}
                                                value={this.state.genderId}
                                                style={this.state.a11 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            >
                                                <option key={0} value={0}>---Select---</option>
                                                {this.state.genderList.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                    </tr>
                                    {/*<tr>*/}
                                    {/*    <td style={{ display: 'flex', justifyContent: 'flex-end' }}>*/}
                                    {/*        <label className={'row-label'}>Race</label>*/}
                                    {/*    </td>*/}
                                    {/*    <td></td>*/}
                                    {/*</tr>*/}
                                    {this.state.raceList.map((item, i) =>
                                        <tr key={i}>
                                            <td>
                                                {i === 0 ?
                                                    <label className={'row-label'}>Race</label>
                                                    : null}
                                            </td>
                                            <td>
                                                <Input type="checkbox"
                                                    className="form-control-checkbox"
                                                    checked={this.state.studentRacesCheckedState[i]}
                                                    onChange={(e) => {
                                                        this.raceChanged(e.target.checked, item.id, item.name);
                                                        var checkedStatus = this.state.studentRacesCheckedState;

                                                        checkedStatus[i] = e.target.checked;
                                                        this.setState({ studentRacesCheckedState: checkedStatus });

                                                    }} />&nbsp;{item.name}
                                            </td>
                                        </tr>
                                        //<option key={i + 1} value={item.id}>{}</option>
                                    )}
                                    {this.state.isRaceOther ?
                                        <tr>
                                            <td>
                                                <label className={'row-label'}>Race (Other)</label>
                                            </td>
                                            <td>
                                                <Input
                                                    ref={this.raceOtherRef}
                                                    type='text'
                                                    onChange={(e) => { this.setState({ raceOther: e.target.value }); }}
                                                    name='raceOther'
                                                    placeholder={'Race (Other)'}
                                                    value={this.state.raceOther ?? ''}
                                                    className="form-control"
                                                    autoFocus
                                                />
                                            </td>
                                        </tr> : null}
                                    <tr>
                                        <td>
                                            <label className={'row-label'}>Ethnicity</label>
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                onChange={(e) => this.setState({ ethnicityId: e.target.value, a12: false })}
                                                value={this.state.ethnicityId}
                                                style={this.state.a12 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            >
                                                <option key={0} value={0}>---Select---</option>
                                                {this.state.ethnicityList.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Medicaid Patient?
                                        </td>
                                        <td>
                                            <Input
                                                type='checkbox'
                                                onChange={(e) => { this.setState({ medicaid: !this.state.medicaid }) }}
                                                checked={this.state.medicaid}
                                                //style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
                                                className="form-control-checkbox"
                                            />
                                        </td>
                                    </tr>
                                    {this.state.medicaid ?
                                        <tr>
                                            <td>
                                                <label className={'row-label'}>Medicaid ID#</label>
                                            </td>
                                            <td>
                                                <Input
                                                    type='text'
                                                    onChange={(e) => { this.setState({ medicaidNumber: e.target.value, a13: false }); }}
                                                    name='medicaidNumber'
                                                    placeholder={this.state.a13 ? 'Required' : 'Medicaid ID#'}
                                                    value={this.state.medicaidNumber ?? ''}
                                                    style={this.state.a13 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    autoFocus
                                                />
                                            </td>
                                        </tr> : null
                                    }
                                    <tr>
                                        <td>
                                            Free / Reduced Lunch?
                                        </td>
                                        <td>
                                            <Input
                                                type='checkbox'
                                                onChange={(e) => { this.setState({ reducedLunch: !this.state.reducedLunch }) }}
                                                checked={this.state.reducedLunch}
                                                //style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
                                                className="form-control-checkbox"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Current Year Exam Consent On File
                                        </td>
                                        <td>
                                            <Input
                                                type='checkbox'
                                                onChange={(e) => { this.setState({ examConsentOnFile: !this.state.examConsentOnFile }) }}
                                                checked={this.state.examConsentOnFile}
                                                //style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
                                                className="form-control-checkbox"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Photo Consent
                                        </td>
                                        <td>
                                            <Input
                                                type='checkbox'
                                                onChange={(e) => { this.setState({ photoConsent: !this.state.photoConsent }) }}
                                                checked={this.state.photoConsent}
                                                //style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
                                                className="form-control-checkbox"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            IEP / 504 Plan
                                        </td>
                                        <td>
                                            <Input
                                                type='checkbox'
                                                onChange={(e) => { this.setState({ IEP504Plan: !this.state.IEP504Plan }) }}
                                                checked={this.state.IEP504Plan}
                                                //style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
                                                className="form-control-checkbox"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Wears Glasses per Nurse
                                        </td>
                                        <td>
                                            <Input
                                                type='checkbox'
                                                onChange={(e) => { this.setState({ wearsGlassesPerNurse: !this.state.wearsGlassesPerNurse }) }}
                                                checked={this.state.wearsGlassesPerNurse}
                                                //style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
                                                className="form-control-checkbox"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col xl='1' lg='2' md='2' sm='2' xs='2' style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <div className={'row-label-input margin-top-5'}>
                                <Input
                                    type='checkbox'
                                    onChange={(e) => { this.setState({ active: !this.state.active }) }}
                                    checked={this.state.active}
                                    className="form-control-checkbox"
                                />
                                <label style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>Active</label>
                            </div>
                        </Col>
                        <Col xl='6' lg='2' md='2' sm='2' xs='2' style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <div style={this.state.contactsDisabled ? { pointerEvents: 'none', opacity: '0.7' } : {}}>
                                <Row>
                                    <Col>
                                        <label style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px', fontSize: '22px', fontWeight: '600', marginBottom: '10px' }}>Parent Information</label>
                                    </Col>
                                </Row><div className={'row-label-input'}>
                                </div>
                                <div className={'row-label-input'}>
                                    <label className={'row-label'}>Parent / Guardian Name</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => { this.setState({ parentName: e.target.value, a14: false }); }}
                                        name='ParentName'
                                        placeholder={this.state.a14 ? 'Required' : 'Parent / Guardian Name'}
                                        value={this.state.parentName ?? ''}
                                        style={this.state.a14 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </div>
                                <div className={'row-label-input margin-top-10'}>
                                    <label className={'row-label'}>Mobile Phone</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => {
                                            var phone = this.formatPhoneNumber(e.target.value)
                                            this.setState({ parentMobilePhone: phone, a15: false });
                                        }}
                                        maxLength={14}
                                        name='Mobile Phone'
                                        placeholder={this.state.a15 ? 'Required' : 'Mobile Phone'}
                                        value={this.state.parentMobilePhone ?? ''}
                                        style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </div>
                                <div className={'row-label-input margin-top-10'}>
                                    <label className={'row-label'}>Email</label>
                                    <Input
                                        ref={this.inputRef}
                                        type='text'
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value, a16: false });
                                        }}
                                        maxLength={100}
                                        name='Email'
                                        placeholder={this.state.a16 ? 'Required' : 'Email'}
                                        value={this.state.email ?? ''}
                                        style={this.state.a16 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </div>
                                <div className={'row-label-input margin-top-10 max-height-15vh-overflow'}>
                                    <table className="gridTable">
                                        <thead>
                                            <tr>
                                                <th>Exam Date</th>
                                                <th>Glasses Needed?</th>
                                                <th>Referral?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.exams.length > 0 ?
                                                this.state.exams.map((item, i) =>
                                                    <tr key={i}>
                                                        {this.state.isDoctor || this.state.isSupport ?
                                                            <td onClick={() => {
                                                                window.location.href = "/ExamPreTest?studentId=" + this.state.studentId + "&examId=" + item.id;

                                                            }}>
                                                                {item.examDate}
                                                            </td>
                                                            :
                                                            <td style={{ cursor: 'default' }}>
                                                                {item.examDate}
                                                            </td>
                                                        }
                                                        {item.glassesNeeded == "Yes" ? <td><div style={{ color: '#445AE4', fontWeight: '500', cursor: 'pointer', display: 'flex', width: '100%', height: '100%' }} onClick={async () => await this.openRx(item.id)}>{item.glassesNeeded}</div></td> : <td>{item.glassesNeeded}</td>}
                                                        {item.referral ?
                                                            <td>
                                                                <div style={{
                                                                    color: '#445AE4',
                                                                    fontWeight: '500',
                                                                    cursor: 'pointer',
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    padding: '0'
                                                                }}
                                                                    onClick={async () => {
                                                                        await this.openReferral(item.id);
                                                                    }}
                                                                >
                                                                    View
                                                                </div>
                                                            </td> :
                                                            <td>
                                                            </td>
                                                        }
                                                    </tr>
                                                ) :
                                                <tr key={1}>
                                                    <td colSpan={3} style={{ textAlign: 'center', fontWeight: '500' }}>
                                                        No Results Found
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                    <table className="gridTable margin-left-5">
                                        <thead>
                                            <tr>
                                                <th>Screening Date</th>
                                                <th>Results</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.screenings.length > 0 ?
                                                this.state.screenings.map((item, i) =>
                                                    <tr key={i} onClick={() => window.location.href = "/Screening?Id=" + item.studentId + "&sid=" + item.id}>
                                                        <td>
                                                            {item.date}
                                                        </td>
                                                        <td>
                                                            {item.results}
                                                        </td>
                                                    </tr>
                                                ) :
                                                <tr>
                                                    <td colSpan={2} style={{ textAlign: 'center', fontWeight: '500' }}>
                                                        No Results Found
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div>
                                <table className="gridTable" style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>
                                                Date Dispensed
                                            </th>
                                            <th>
                                                Dispense Type
                                            </th>
                                            <th>
                                                Manufacturer
                                            </th>
                                            <th>
                                                Model
                                            </th>
                                            <th>
                                                Color
                                            </th>
                                            <th colSpan={2}>
                                                Eye Size
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.labFrames && this.state.labFrames.length > 0 ?
                                            this.state.labFrames.map((item, i) =>
                                                <tr key={i}>
                                                    <td>
                                                        {item.isReplacement === 'Yes' ?
                                                            <div style={{ color: '#0000ee', cursor: 'pointer' }} onClick={() => window.location.href = "/ReplacementDetails?Id=" + item.id}>{item.dateDispensed}</div>
                                                            :
                                                            item.dateDispensed
                                                        }
                                                    </td>
                                                    <td>
                                                        {item.dispenseType}
                                                    </td>
                                                    <td>
                                                        {item.manufacturer}
                                                    </td>
                                                    <td>
                                                        {item.model}
                                                    </td>
                                                    <td>
                                                        {item.color}
                                                    </td>
                                                    <td>
                                                        {item.eyeSize}
                                                    </td>
                                                    <td align="right" valign="middle" style={{ borderLeft: '0', width: '25px' }} className="studentDetailsFramesTrash">
                                                        <IoTrashOutline
                                                            color={this.state.hover ? "#ff0000" : "#000"}
                                                            size={21} style={{ cursor: 'pointer' }}
                                                            onClick={() => this.deleteFramePrompt(item.id)}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                            :
                                            <tr>
                                                <td colSpan={5} style={{ textAlign: 'center', fontWeight: '500' }}>
                                                    No Results Found
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div style={this.state.uploadDisabled ? { pointerEvents: 'none', opacity: '0.5' } : {}}>
                                <Container style={{ marginTop: '50px' }}>
                                    <Row>
                                        <Col>
                                            <label style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px', fontSize: '22px', fontWeight: '600' }}>Uploads</label>
                                        </Col>
                                    </Row>
                                    <Upload StudentId={this.state.studentId} />
                                    <br />
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/*HEALTH HISTORY*/}
                <table className="healthHistoryTable">
                    <tbody>
                        <tr>
                            <td colSpan={4} align="left">
                                <label style={{ width: 'fit-content', fontSize: '22px', fontWeight: '600', paddingTop: '10px' }}>Health History Per Current Consent Form</label>
                            </td>
                        </tr>
                        <tr>
                            <td align="right">
                                Has your child ever received an eye exam?
                            </td>
                            <td>
                                <Input
                                    type='radio'
                                    name='receivedExam'
                                    onChange={(e) => { this.setState({ ReceivedEyeExam: e.target.checked ? 'Yes' : 'No' }) }}
                                    checked={this.state.ReceivedEyeExam == 'Yes' ? true : false}
                                    className="form-control-checkbox"
                                />&nbsp;Yes&nbsp;&nbsp;
                                <Input
                                    type='radio'
                                    name='receivedExam'
                                    onChange={(e) => { this.setState({ ReceivedEyeExam: e.target.checked ? 'No' : 'Yes' }) }}
                                    checked={this.state.ReceivedEyeExam == 'No' ? true : false}
                                    className="form-control-checkbox"
                                />&nbsp;No
                            </td>
                            <td align="left">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    If yes, was it from Eye Thrive?&nbsp;&nbsp;
                                    <input
                                        type='radio'
                                        name='receivedexamfromeyethrive'
                                        onChange={(e) => { this.setState({ ReceivedEyeExamFromEyeThrive: e.target.checked ? 'Yes' : 'No' }) }}
                                        checked={this.state.ReceivedEyeExamFromEyeThrive == 'Yes' ? true : false}
                                        className="form-control-checkbox"
                                    />&nbsp;Yes&nbsp;&nbsp;
                                    <input
                                        type='radio'
                                        name='receivedexamfromeyethrive'
                                        onChange={(e) => { this.setState({ ReceivedEyeExamFromEyeThrive: e.target.checked ? 'No' : 'Yes' }) }}
                                        checked={this.state.ReceivedEyeExamFromEyeThrive == 'No' ? true : false}
                                        className="form-control-checkbox"
                                    />&nbsp;No
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td align="right">
                                Has your child ever been prescribed glasses?
                            </td>
                            <td>
                                <input
                                    type='radio'
                                    name='prescribedglasses'
                                    onChange={(e) => { this.setState({ PrescribedGlasses: e.target.checked ? 'Yes' : 'No' }) }}
                                    checked={this.state.PrescribedGlasses == 'Yes' ? true : false}
                                    className="form-control-checkbox"
                                />&nbsp;Yes&nbsp;&nbsp;
                                <input
                                    type='radio'
                                    name='prescribedglasses'
                                    onChange={(e) => { this.setState({ PrescribedGlasses: e.target.checked ? 'No' : 'Yes' }) }}
                                    checked={this.state.PrescribedGlasses == 'No' ? true : false}
                                    className="form-control-checkbox"
                                />&nbsp;No
                            </td>
                            <td align="left">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    If yes, how long ago?&nbsp;&nbsp;
                                    <input
                                        type='text'
                                        onChange={(e) => { this.setState({ PrescribedGlassesHowLongAgo: e.target.value }) }}
                                        value={this.state.PrescribedGlassesHowLongAgo ?? ''}
                                        className="form-control"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td align="right">
                                Does your child wear glasses now?
                            </td>
                            <td colSpan={3}>
                                <input
                                    type='radio'
                                    name='wearsglassesnow'
                                    onChange={(e) => { this.setState({ ChildWearsGlassesNow: e.target.checked ? 'Yes' : 'No' }) }}
                                    checked={this.state.ChildWearsGlassesNow === 'Yes' ? true : false}
                                    className="form-control-checkbox"
                                />&nbsp;Yes&nbsp;&nbsp;
                                <input
                                    type='radio'
                                    name='wearsglassesnow'
                                    onChange={(e) => { this.setState({ ChildWearsGlassesNow: e.target.checked ? 'No' : 'Yes' }) }}
                                    checked={this.state.ChildWearsGlassesNow === 'No' ? true : false}
                                    className="form-control-checkbox"
                                />&nbsp;No
                            </td>
                        </tr>
                        <tr>
                            <td align="right">
                                Does your child complain of blurry vision?
                            </td>
                            <td colSpan={3}>
                                <input
                                    type='radio'
                                    name='blurryvision'
                                    onChange={(e) => { this.setState({ BlurryVision: e.target.checked ? 'Yes' : 'No' }) }}
                                    checked={this.state.BlurryVision == 'Yes' ? true : false}
                                    className="form-control-checkbox"
                                />&nbsp;Yes&nbsp;&nbsp;
                                <input
                                    type='radio'
                                    name='blurryvision'
                                    onChange={(e) => { this.setState({ BlurryVision: e.target.checked ? 'No' : 'Yes' }) }}
                                    checked={this.state.BlurryVision == 'No' ? true : false}
                                    className="form-control-checkbox"
                                />&nbsp;No
                            </td>
                        </tr>
                        <tr>
                            <td align="right">
                                Has your child ever injured or had surgeries on his/her eyes?
                            </td>
                            <td colSpan={3}>
                                <input
                                    type='radio'
                                    name='surgeries'
                                    onChange={(e) => { this.setState({ SurgeriesOnEyes: e.target.checked ? 'Yes' : 'No' }) }}
                                    checked={this.state.SurgeriesOnEyes == 'Yes' ? true : false}
                                    className="form-control-checkbox"
                                />&nbsp;Yes&nbsp;&nbsp;
                                <input
                                    type='radio'
                                    name='surgeries'
                                    onChange={(e) => { this.setState({ SurgeriesOnEyes: e.target.checked ? 'No' : 'Yes' }) }}
                                    checked={this.state.SurgeriesOnEyes == 'No' ? true : false}
                                    className="form-control-checkbox"
                                />&nbsp;No
                            </td>
                        </tr>
                        <tr>
                            <td align="right" valign="middle">
                                Does your child have diabetes?
                            </td>
                            <td align="left" valign="top" colSpan={3}>
                                <div style={{ width: 'fit-content', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <input
                                        type='radio'
                                        name='ChildHaveDiabetes'
                                        onChange={(e) => { this.setState({ ChildHaveDiabetes: e.target.checked ? 'Yes' : 'No', examSaved: false }) }}
                                        checked={this.state.ChildHaveDiabetes == 'Yes' ? true : false}
                                        className="form-control-checkbox"
                                    />&nbsp;Yes&nbsp;&nbsp;
                                    <input
                                        type='radio'
                                        name='ChildHaveDiabetes'
                                        onChange={(e) => { this.setState({ ChildHaveDiabetes: e.target.checked ? 'No' : 'Yes', examSaved: false }) }}
                                        checked={this.state.ChildHaveDiabetes == 'No' ? true : false}
                                        className="form-control-checkbox"
                                    />&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <span>If yes, A1C Number?</span>&nbsp;
                                        <Input
                                            type='text'
                                            onChange={(e) => {
                                                this.setState({ a1c: e.target.value });
                                            }}
                                            name='A1CNumber'
                                            placeholder={''}
                                            value={this.state.a1c ?? ''}
                                            style={{ padding: '3px', width: '45px' }}
                                        />
                                    </div>&nbsp;&nbsp;
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <span>If yes, year of diabetes diagnosis?</span>&nbsp;
                                        <Input
                                            type='numeric'
                                            onChange={(e) => {
                                                this.setState({ diabetesYear: e.target.value });
                                            }}
                                            name='DiabetesYear'
                                            maxLength={4}
                                            placeholder={''}
                                            value={this.state.diabetesYear ?? ''}
                                            style={{ padding: '3px', width: '50px' }}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" align="right">
                                Please list any medications your child is currently taking
                            </td>
                            <td colSpan={3}>
                                <textarea
                                    onChange={(e) => { this.setState({ ListOfMedications: e.target.value }) }}
                                    value={this.state.ListOfMedications ?? ''}
                                    className="form-control"
                                    style={{ height: '60px' }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" align="right">
                                Please list any food or medication allergies your child has
                            </td>
                            <td colSpan={3}>
                                <textarea
                                    onChange={(e) => { this.setState({ ListOfAllergies: e.target.value }) }}
                                    value={this.state.ListOfAllergies ?? ''}
                                    className="form-control"
                                    style={{ height: '60px' }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" align="right">
                                Please list any medical conditions your child as been diagnosed with
                            </td>
                            <td colSpan={3}>
                                <textarea
                                    onChange={(e) => { this.setState({ MedicalConditions: e.target.value }) }}
                                    value={this.state.MedicalConditions ?? ''}
                                    className="form-control"
                                    style={{ height: '60px' }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" align="right">
                                Please list any family history of eye disease
                            </td>
                            <td colSpan={3}>
                                <textarea
                                    onChange={(e) => { this.setState({ EyeDiseaseHistory: e.target.value }) }}
                                    value={this.state.EyeDiseaseHistory ?? ''}
                                    className="form-control"
                                    style={{ height: '60px' }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button
                        color='primary'
                        style={{ marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap', padding: '3px 20px', borderRadius: '5px', border: '0' }}
                        onClick={() => this.handleSaveStudent()}
                        disabled={this.state.loading ? true : false}
                    >
                        Save
                    </Button>
                    {this.state.username === 'katemckearn@eyethrive.org' ||
                        this.state.username === 'brian@swipsystems.com' ||
                        this.state.username === 'adamblumoff@eyethrive.org' ||
                        this.state.username === 'ashleywildermuth@eyethrive.org' ||
                        this.state.username === 'DebeauxBowman@eyethrive.org' ||
                        this.state.username === 'odedlevinstein@eyethrive.org' ?
                        <Button
                            className='delete'
                            style={{ marginLeft: '10px', marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap', padding: '3px 20px', borderRadius: '5px', border: '0' }}
                            onClick={() => this.handleDeleteStudent()}
                            disabled={this.state.loading ? true : false}
                        >
                            Delete Student
                        </Button> : null}
                </div>
                <div>
                    <Modal
                        isOpen={this.state.showDeleteStudentModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showDeleteStudentModal: false })}
                        className="et-modal"
                        contentLabel="Modal"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h5>Delete Student Confirmation</h5>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', maxHeight: '30vh', overflow: 'auto' }}>
                            <table style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <div style={{ width: '100%', display: 'flex', textAlign: 'center', fontSize: '16px' }}>
                                                This action will delete the student and all data associated to this student.<br /><br />
                                                Are you sure you want to delete the student?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <Button
                                                    color='primary'
                                                    style={{
                                                        marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap',
                                                        padding: '3px 20px', borderRadius: '5px', border: '0'
                                                    }}
                                                    onClick={() => {
                                                        this.setState({ showDeleteStudentModal: false })
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className='delete'
                                                    style={{
                                                        marginLeft: '40px', marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap',
                                                        padding: '3px 20px', borderRadius: '5px', border: '0'
                                                    }}
                                                    onClick={() => {
                                                        this.deleteStudent();
                                                        this.setState({ showDeleteStudentModal: false })
                                                    }}
                                                >
                                                    Delete Student
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Modal>
                </div>
                <div>
                    <Modal
                        isOpen={this.state.showModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showModal: false })}
                        className="et-modal"
                        contentLabel="Example Modal"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h5>Delete Frame?</h5>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '15px' }}>
                            <button
                                color='primary'
                                style={{ marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap', padding: '3px 20px', borderRadius: '5px', border: '0' }}
                                onClick={() => this.setState({ showModal: false })}
                            >
                                No
                            </button>
                            <button
                                color='primary'
                                style={{ marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap', padding: '3px 20px', borderRadius: '5px', border: '0' }}
                                onClick={() => this.deleteFrame()}
                            >
                                Yes
                            </button>
                        </div>
                    </Modal>
                </div>

            </div>
        )
    }

    handleDeleteStudent() {
        this.setState({ showDeleteStudentModal: true });
    }

    raceChanged(checked, id, name) {
        if (name === "Other") {
            this.setState({ isRaceOther: checked });
        }

        var _races = this.state.studentRaces;
        if (checked) {
            if (!_races.includes(id.toUpperCase())) {
                _races.push(id.toUpperCase());
            }
        }
        else {
            var index = _races.indexOf(id.toUpperCase())

            if (index !== -1) {
                _races.splice(index, 1);
            }
        }
        this.setState({ studentRaces: _races });
    }

    async deleteStudent() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        var dto = {
            Id: this.state.studentId
        };

        await fetch('api/Student/DeleteStudent', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response;
                }
            })
            .then((data) => {
                if (data) {
                    window.location.href = "/StudentSearch";
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in deleteStudent - ' + error);
            });
    }

    async openRx(id) {
        const token = await authService.getAccessToken();

        await fetch('api/Lab/GenerateRxPdf?examId=' + id, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(response => response.blob())
            .then(data => {
                window.open(URL.createObjectURL(data), "_blank");
            })
    }

    async openReferral(id) {
        const token = await authService.getAccessToken();

        await fetch('api/Exam/GetReferralPdf?examId=' + id, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(response => response.blob())
            .then(data => {
                window.open(URL.createObjectURL(data), "_blank");
            })
    }

    validateEmail() {
        if (this.state.email && this.state.email.length != 0) {
            if (!userFunctions.validateEmail(this.state.email)) {
                this.setState({ showEmailError: true, toastErrorMessage: "The email address enter is not a valid format", a16: true });
                this.hideToast();
                if (this.inputRef.current) {
                    this.inputRef.current.focus();
                }
                return false;
            }
        }
        return true;
    }

    async getDistricts() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetDistricts', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ districts: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                alert('error in getDistricts - ' + error.message, 'color:#ff0000')
                this.setState({ loading: false });
            });
    }

    async getTeachersList() {
        const token = await authService.getAccessToken();
        var response = await fetch('api/student/getteacherslist', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        var data = await response.json();
        this.setState({ teacherList: data });
    }

    async getSitesByDistrictId(districtId, showDistrictOther) {
        if (districtId == 0) {
            this.setState({ siteList: null, showDistrictOther: false });
        }
        else {
            this.setState({ loading: true });
            var _this = this;

            const token = await authService.getAccessToken();
            await fetch('api/Sites/GetSitesByDistrictId?id=' + districtId, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(async (response) => {
                    return response.json();
                })
                .then((data) => {
                    this.setState({ siteList: data }, function () {
                        this.setDistrictOther(_this, showDistrictOther);
                    });
                    this.setState({ loading: false });
                })
                .catch((error) => {
                    alert('error in getSitesByDistrictId - ' + error.message, 'color:#ff0000')
                });
        }
    }

    setDistrictOther(_this, showDistrictOther) {
        if (showDistrictOther) {
            var id = "";
            _this.state.siteList.find(function (item, i) {
                if (item.name == 'Other') {
                    id = item.id;
                }
            });
            this.setState({ siteId: id });
        }
        this.setState({ showDistrictOther: showDistrictOther });
    }

    async getGrades() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetGrades', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({ gradeList: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                alert('error in getGrades - ' + error.message, 'color:#ff0000')
                this.setState({ loading: false });
            });
    }

    async getGenders() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetGenders', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({ genderList: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                alert('error in getGenders - ' + error.message, 'color:#ff0000')
                this.setState({ loading: false });
            });
    }

    async getEthnicities() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetEthnicities', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({ ethnicityList: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                alert('error in getEthnicities - ' + error.message, 'color:#ff0000')
                this.setState({ loading: false });
            });
    }

    async getRaceList() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/student/GetRaceList', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json();
            })
            .then((data) => {
                var checkedStatus = this.state.studentRacesCheckedState;

                //alert(JSON.stringify(this.state.studentRaces) + ' ||||||| ' + JSON.stringify(data))

                for (var i = 0; i < data?.length; i++) {
                    var _id = data[i].id;
                    var _raceName = data[i].name;

                    // eslint-disable-next-line no-loop-func
                    var isChecked = this.state.studentRaces.some(s => s.toLowerCase() === _id.toLowerCase());

                    if (isChecked) {
                        checkedStatus[i] = true;
                    }
                    else {
                        checkedStatus[i] = false;
                    }
                }


                //alert(JSON.stringify(checkedStatus))
                this.setState({ studentRacesCheckedState: checkedStatus });

                this.setState({ raceList: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                alert('error in getRaceList - ' + error.message, 'color:#ff0000')
                this.setState({ loading: false });
            });
    }

    // table sort
    GetIdFromQuery(location) {
        //#!?lid=2&sales=2
        var id = location.search().Id;
        if (id == null) return 0;
        else return id;
    }

    async GetStudentById(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        const query = id;
        var _this = this;

        await fetch(`api/Student/GetStudentById?Id=${query}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status === 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({
                    active: data.active,
                    firstName: data.firstName ?? '',
                    preferredName: data.preferredName ?? '',
                    lastName: data.lastName ?? '',
                    dateOfBirth: data.dateOfBirth ?? '',
                    zip: data.zip ?? '',
                    districtId: data.districtId,
                    siteId: data.siteId,
                    gradeId: data.gradeId,
                    teacher: data.teacher ?? '',
                    roomNumber: data.roomNumber ?? '',
                    genderId: data.genderId,
                    ethnicityId: data.ethnicityId,
                    isRaceOther: data.isRaceOther,
                    studentRaces: data.studentRaces,
                    raceOther: data.raceOther,
                    medicaid: data.medicaidPatient,
                    medicaidNumber: data.medicaidIdNumber ?? '',
                    reducedLunch: data.reducedLunch,
                    examConsentOnFile: data.examConsentOnFile,
                    photoConsent: data.photoConsent,
                    IEP504Plan: data.ieP504Plan,
                    wearsGlassesPerNurse: data.wearsGlassesPerNurse,
                    parentName: data.parentName ?? '',
                    parentMobilePhone: data.parentMobilePhone ?? '',
                    email: data.parentEmail ?? '',

                    ReceivedEyeExam: data.receivedEyeExam == true ? 'Yes' : data.receivedEyeExam == null ? undefined : 'No',
                    ReceivedEyeExamFromEyeThrive: data.receivedEyeExamFromEyeThrive == true ? 'Yes' : data.receivedEyeExamFromEyeThrive == null ? undefined : 'No',
                    PrescribedGlasses: data.prescribedGlasses == true ? 'Yes' : data.prescribedGlasses == null ? undefined : 'No',
                    PrescribedGlassesHowLongAgo: data.prescribedGlassesHowLongAgo,
                    ChildWearsGlassesNow: data.childWearsGlassesNow == true ? 'Yes' : data.childWearsGlassesNow == null ? undefined : 'No',
                    BlurryVision: data.blurryVision == true ? 'Yes' : data.blurryVision == null ? undefined : 'No',
                    SurgeriesOnEyes: data.surgeriesOnEyes == true ? 'Yes' : data.surgeriesOnEyes == null ? undefined : 'No',
                    ChildHaveDiabetes: data.childHaveDiabetes == true ? 'Yes' : data.childHaveDiabetes == null ? undefined : 'No',
                    ListOfMedications: data.listOfMedications,
                    ListOfAllergies: data.listOfAllergies,
                    MedicalConditions: data.medicalConditions,
                    EyeDiseaseHistory: data.eyeDiseaseHistory,

                    districtOther: data.districtOther,
                    schoolOther: data.schoolOther,
                    uploadDisabled: false,
                    loading: false,
                    screenings: data.screeningDTO,
                    a1c: data.a1C,
                    diabetesYear: data.diabetesYear
                }, async function () {

                    if (this.state.districtId && this.state.districtId !== 0 && this.state.districtId !== userFunctions.GuidEmpty()) {
                        var showDistrictOther = false;

                        _this.state.districts.find(function (item, i) {
                            if (item.id === _this.state.districtId && item.name === 'Other') {
                                showDistrictOther = true;
                                return true;
                            }
                        });

                        _this.getSitesByDistrictId(_this.state.districtId, showDistrictOther);
                    }

                    this.getRaceList();
                });
            })
            .catch(error => {
                console.log("error in GetStudentById - " + error);
                this.setState({ loading: false });
            });;
    }

    async signout() {
        window.location.href = "/";
    }

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.sites;
        data.sort((a, b) => a[column].localeCompare(b[column]))
        if (direction === 'desc') {
            data.reverse();
        }
        this.setState({
            sites: data,
            sort: {
                column,
                direction
            }
        })
    }

    async handleSaveStudent() {
        if (this.state.firstName.length === 0) {
            this.setState({ a1: true });
        }
        else if (this.state.lastName.length === 0) {
            this.setState({ a3: true });
        }
        else if (this.state.dateOfBirth.length === 0) {
            this.setState({ a4: true });
        }
        //else if (this.state.zip.length == 0) {
        //    this.setState({ a5: true });
        //}
        //else if (this.state.districtId == 0) {
        //    this.setState({ a6: true });
        //}
        //else if (this.state.siteId == 0) {
        //    this.setState({ a7: true });
        //}
        //else if (this.state.gradeId == 0) {
        //    this.setState({ a8: true });
        //}
        //else if (this.state.teacher.length == 0) {
        //    this.setState({ a9: true });
        //}
        //else if (this.state.roomNumber.length == 0) {
        //    this.setState({ a10: true });
        //}
        //else if (this.state.genderId == 0) {
        //    this.setState({ a11: true });
        //}
        //else if (this.state.ethnicityId == 0) {
        //    this.setState({ a12: true });
        //}
        //else if (this.state.medicaid && this.state.medicaidNumber.length == 0) {
        //    this.setState({ a13: true });
        //}
        //else if (this.state.parentName.length == 0) {
        //    this.setState({ a14: true });
        //}
        //else if (this.state.parentMobilePhone.length == 0) {
        //    this.setState({ a15: true });
        //}
        //else if (this.state.email.length == 0) {
        //    this.setState({ a16: true });
        //}
        else {
            if (this.validateEmail()) {
                const token = await authService.getAccessToken();
                var dto = {
                    // student info
                    Id: this.state.studentId === userFunctions.GuidEmpty() ? null : this.state.studentId,
                    Active: this.state.active,
                    FirstName: this.state.firstName ?? null,
                    PreferredName: this.state.preferredName ?? null,
                    LastName: this.state.lastName ?? null,
                    DateOfBirth: this.state.dateOfBirth ?? null,
                    Zip: this.state.zip ?? null,
                    DistrictId: this.state.districtId === userFunctions.GuidEmpty() ? null : this.state.districtId,
                    SiteId: this.state.siteId === userFunctions.GuidEmpty() ? null : this.state.siteId,
                    GradeId: this.state.gradeId === userFunctions.GuidEmpty() ? null : this.state.gradeId,
                    Teacher: this.state.teacher ?? null,
                    RoomNumber: this.state.roomNumber ?? null,
                    GenderId: this.state.genderId === userFunctions.GuidEmpty() ? null : this.state.genderId,
                    EthnicityId: this.state.ethnicityId === userFunctions.GuidEmpty() ? null : this.state.ethnicityId,
                    StudentRaces: this.state.studentRaces,
                    RaceOther: this.state.raceOther == null || this.state.raceOther === '' ? null : this.state.raceOther,
                    IsRaceOther: this.state.isRaceOther,
                    // student info checkboxes
                    MedicaidPatient: this.state.medicaid,
                    MedicaidIdNumber: this.state.medicaidNumber ?? null,
                    ReducedLunch: this.state.reducedLunch,
                    ExamConsentOnFile: this.state.examConsentOnFile,
                    PhotoConsent: this.state.photoConsent,
                    IEP504Plan: this.state.IEP504Plan,
                    WearsGlassesPerNurse: this.state.wearsGlassesPerNurse,
                    // parent info
                    ParentName: this.state.parentName ?? null,
                    ParentMobilePhone: this.state.parentMobilePhone ?? null,
                    ParentEmail: this.state.email ?? null,

                    ReceivedEyeExam: this.state.ReceivedEyeExam === undefined ? null : this.state.ReceivedEyeExam === 'Yes' ? true : false,
                    ReceivedEyeExamFromEyeThrive: this.state.ReceivedEyeExamFromEyeThrive === undefined ? null : this.state.ReceivedEyeExamFromEyeThrive === 'Yes' ? true : false,
                    PrescribedGlasses: this.state.PrescribedGlasses === undefined ? null : this.state.PrescribedGlasses === 'Yes' ? true : false,
                    PrescribedGlassesHowLongAgo: this.state.PrescribedGlassesHowLongAgo === undefined ? null : this.state.PrescribedGlassesHowLongAgo,
                    ChildWearsGlassesNow: this.state.ChildWearsGlassesNow === undefined ? null : this.state.ChildWearsGlassesNow === 'Yes' ? true : false,
                    BlurryVision: this.state.BlurryVision === undefined ? null : this.state.BlurryVision === 'Yes' ? true : false,
                    SurgeriesOnEyes: this.state.SurgeriesOnEyes === undefined ? null : this.state.SurgeriesOnEyes === 'Yes' ? true : false,
                    ChildHaveDiabetes: this.state.ChildHaveDiabetes === undefined ? null : this.state.ChildHaveDiabetes === 'Yes' ? true : false,
                    ListOfMedications: this.state.ListOfMedications === undefined ? null : this.state.ListOfMedications,
                    ListOfAllergies: this.state.ListOfAllergies === undefined ? null : this.state.ListOfAllergies,
                    MedicalConditions: this.state.MedicalConditions === undefined ? null : this.state.MedicalConditions,
                    EyeDiseaseHistory: this.state.EyeDiseaseHistory === undefined ? null : this.state.EyeDiseaseHistory,
                    DistrictOther: this.state.districtOther === undefined ? null : this.state.districtOther,
                    SchoolOther: this.state.schoolOther === undefined ? null : this.state.schoolOther,
                    A1C: this.state.a1c,
                    DiabetesYear: this.state.diabetesYear
                };

                await this.saveStudent(dto, token);
            }
        }
    }

    async saveStudent(dto, token) {
        this.setState({ loading: true });
        await fetch('api/Student/SaveStudent', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status === 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ toastMessage: 'Student Information saved successfully' });
                this.setState({ showToast: true });

                this.setState({ studentId: data.id });

                data.id != undefined || data.id != null || data.id != userFunctions.GuidEmpty() ? this.setState({ uploadDisabled: false }) : this.setState({ uploadDisabled: true });

                this.setState({ loading: false });
                this.hideToast();
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in saveStudent - ' + error);
            });
        //this.setState({ success: data });
    }

    hideToast() {
        setTimeout(() => {
            this.setState({ toastMessage: '', toastErrorMessage: '', showToast: false, showEmailError: false });
        }, 3500);
    }

    formatPhoneNumber(phoneNumber) {
        var result;

        result = phoneNumber.replace(/[^0-9]/g, '');
        if (result.length == 10) {
            result = result.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        }
        else {
            result = result.replace(/\//g, "");
        }

        return result;
    }

    async GetExamGrid(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        const query = id;

        await fetch('api/exam/getExamGrid?studentId=' + id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    exams: data,
                });
            })
            .catch(error => {
                console.log("error in GetExamGrid - " + error);
                this.setState({ loading: false });
            });;
    }
}